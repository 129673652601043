<template>
  <div v-bind="getRootProps()"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
    :class="{ 'active-dropzone_contentedit': active }"
    class="dropzone_files_contentedit"
    v-if='!isUploading'>
      <input v-bind="getInputProps()"  id="dropzoneFile" class="dropzoneFile" />
  </div>
  <div v-if='isUploading' class='gauge'>
    <highcharts :p="percentComplete" :h="ht"></highcharts>
  </div>
  
</template>

<script>
/* eslint-disable */
import {inject, ref} from 'vue';
import {useStore} from "vuex";
import {useDropzone} from 'vue3-dropzone';
import MapChart from '../highcharts/solidgauge';
import {v4 as uuidv4} from 'uuid';
import getmime from './mimetypes.js';

export default {
  name: 'DropZone',
  inject: ['apiPath'],
  components: {
    highcharts: MapChart,
  },
  props: ['docid', 'n','index','height'],
  emits: ['uploadCompleted','uploadStarted'],
  setup(props,context) {
    const store = useStore();
    const apiPath = inject('apiPath');
    const percentComplete = ref(0);
    const isUploading = ref(false);
    let ht = ref(0);
    ht = props.height;
    console.log("Height set in cloudflare comp" + ht);

    async function getOneTimeUploadUrl() {
      let response = await fetch(`${apiPath}cloudflareGetUploadLinkImage?token=${store.state.usertoken}&tenant=${localStorage.getItem("currenttenant")}`);
      return await response.text();
    }
    function progressHandler(event) {
      let bytes = event.loaded;
      let showbytes = 0;
      let units = '';
      let total = 0;
      let percent = 0;
      if (bytes > 1000) {
        showbytes = bytes / 1000;
        units = 'KB';
      }
      if (bytes > 1000000) {
        showbytes = bytes / 1000000;
        units = 'MB';
      }
      const loaded = `${showbytes.toFixed(2)} ${units}`;

      bytes = event.total;
      showbytes = 0;
      units = '';
      if (bytes > 1000) {
        showbytes = bytes / 1000;
        units = 'KB';
      }
      if (bytes > 1000000) {
        showbytes = bytes / 1000000;
        units = 'MB';
      }
      total = `${showbytes.toFixed(2)} ${units}`;
      percent = (event.loaded / event.total) * 100;
      console.log("Event Loaded:",event.loaded,"; Event Total:",event.total);
      percentComplete.value = Math.round(percent);
    }

    const completeHandler = (event) => {
      const data = event.target.responseText;
      console.log("content_edit attachment upload complete");
      isUploading.value = false;
      context.emit("uploadCompleted",props.index);
    }

    function errorHandler(event) {
      context.emit("uploadCompleted",-1);
    }

    function abortHandler(event) {
      context.emit("uploadCompleted",-2);
    }

    async function uploadFile(files) {
      files.forEach(async function(file) {
        let isImage = false;
        let uploadURL = '';
        let ext = "";
        const formdata = new FormData();
        const ajax = new XMLHttpRequest();
        let newId = uuidv4();

        ext = file.name.split('.').pop();
        isImage = file.name.includes(".png") || file.name.includes(".jpg") || file.name.includes(".gif") || file.name.includes(".svg") || file.name.includes(".jpeg");
        if (!isImage) {
            uploadURL = "https://test-remove-this.wave7.workers.dev/" + newId + "." + ext;
            ajax.open('PUT', uploadURL);
            ajax.setRequestHeader("X-Custom-Auth-Key", "hotdog");
            ajax.setRequestHeader("Content-type", getmime(ext));
        } else {
          uploadURL = await getOneTimeUploadUrl();
          ajax.open('Post', uploadURL);
        }
        
        ajax.upload.addEventListener('progress', progressHandler, false);
        ajax.addEventListener('load', completeHandler, false);
        ajax.addEventListener('error', errorHandler, false);
        ajax.addEventListener('abort', abortHandler, false);

        if(ext=='pdf' || isImage) {
          formdata.append('file', file);
          ajax.send(formdata);
        } else {
          ajax.send(file);
        }
        
        context.emit("uploadStarted",uploadURL,props.index);
        let attachmentData = [];
        if(!isImage)
        {
          attachmentData = [file.name,ext + "_img",file.size,"https://test-remove-this.wave7.workers.dev/" + newId + "." + ext];
        }
        else
        {
          attachmentData = [file.name,'img_img',file.size,uploadURL.replace("upload.", "") + '/thumb'];
        }
    
        //THIS SECTION NEEDS TO BE CHANGED SO THAT THIS COMPONENT CAN BE SHARED---------------
        if (store.state.postUploadFiles === undefined || store.state.postUploadFiles == null) {
          store.state.postUploadFiles = [];
        }
        store.state.postUploadFiles.push(attachmentData);

        
        if (store.state.helpUploadFiles === undefined || store.state.helpUploadFiles == null) {
          store.state.helpUploadFiles = [];
        }
        store.state.helpUploadFiles.push(attachmentData);
        //------------------------------------------------------------------------------------
      });

      return true;
    }

    function onDrop(acceptFiles, rejectReasons) {
      uploadFile(acceptFiles);
      isUploading.value = true;
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };

    return {
      active,
      ht,
      percentComplete,
      isUploading,
      progressHandler,
      completeHandler,
      toggleActive,
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>

<style lang="scss" scoped>

*{
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
}
.dropzone {
  position:absolute;
  width: inherit;
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
}
.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #41b883;
  label {
    background-color: #fff;
    color: #41b883;
  }
}
.gauge {
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
  position: absolute;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  font-size: 12px;
}
</style>
