<template>

  <div class="notifications">
    <transition name="dropdown">
      <div v-if="this.$store.state.ifLoading" class="notification_loading">
        <div class="rotate"></div>Saving data
      </div>
    </transition>

    <transition name="dropdown">
      <div v-if="this.$store.state.ifSaved" class="notification_saved">
        Saved!
      </div>
    </transition>

    <transition name="dropdown">
      <div v-if="this.$store.state.ifError" class="notification_error">
        Save failed
      </div>
    </transition>

  </div>

  <div class="breadcrumb_popup" v-if="$store.state.breadcrumbPopup">
    <p>Your Location:</p>
    <breadcrumbtree></breadcrumbtree>
    <btn class="secondary" v-text="'close'" @click=" $store.state.breadcrumbPopup = false"></btn>
  </div>
  <transition name="fade">
    <shade v-if="$store.state.breadcrumbPopup" @click="$store.state.breadcrumbPopup = false;"></shade>
  </transition>


  <!-- <transition name="slide-fade" mode="out-in"> -->
  <router-view :key="$route.fullPath" />
  <!-- </transition> -->

  <transition name="slide-fade">
    <footer-menu v-if="hideBottom"></footer-menu>
  </transition>

  <top-bar v-if="hideHeader"></top-bar>
  <notifications classes="saved" />
</template>

<script>
import { defineAsyncComponent, isProxy, toRaw, inject } from 'vue';
import { useStore } from "vuex";
import Session from "supertokens-web-js/recipe/session";
import { useRouter } from "vue-router";
import btn from './components/buttons/button.vue';
import Breadcrumbtree from './components/breadcrumbtree/breadcrumbtree.vue';

const topBar = defineAsyncComponent(
  () => import('./components/layout/top-bar.vue'),
);

const footerMenu = defineAsyncComponent(
  () => import('./components/layout/menu.vue'),
);

const shade = defineAsyncComponent(
  () => import('./components/shade/shade.vue'),
);

export default {
  name: 'App',
  inject: ['apiPath'],
  components: {
    topBar,
    footerMenu,
    Breadcrumbtree,
    btn,
    shade,
  },
  created() {
    if (localStorage.edit == undefined) {
      localStorage.setItem('edit', false);
    }
    if (localStorage.edit == 'false') {
      this.$store.state.edit = false;
    } else {
      this.$store.state.edit = true;
    }
    if (this.$route.name != 'Help') {
      localStorage.removeItem('letter');
    }
    // document.querySelector('html').style.fontSize = '12px';
    // setTimeout(
    //   () => {
    //     document.querySelector('html').style.fontSize = '30px';
    //   }, 5000
    // );
  },
  setup() {
    const store = useStore();
    //const newUser = inject('user'); //todo use newUser in all vue files
    //store.state.startpage = '/';
    //store.state.startpagename = 'Home';
    store.state.loggedin = true;

    store.state.usertoken = 'temptoken';

    let bOpus_user = localStorage.getItem("bOpus_user");
    if (bOpus_user !== null) {
      store.state.user = JSON.parse(bOpus_user);
      store.state.usertoken = store.state.user.token; //todo change call in code to get rid from usertoken and use as user.token
      // if (store.state.user.metadata !== undefined) {
      //   store.state.startpage = store.state.user.metadata.startpage;
      //   store.state.startpagename = store.state.user.metadata.startpagename;
      // }
      store.state.isEditable = true; //users can edit to, if they have right permission
      if (store.state.user.role && store.state.user.role.length > 0 && (store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin'))) {
        //store.commit('ifAdmin');
        store.state.isEditable = true;
      } else if (!store.state.user.role) {
        store.state.user.role = [];
      }
    } else {
      localStorage.removeItem("bOpus_user");
      localStorage.removeItem("exposedTreeNodes");
      Session.signOut().then(() => {
        const router = useRouter();
        router.push('/login');
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    }

    let editMode = localStorage.getItem('editMode');
    if (editMode === 'true') {
      store.state.edit = true;
    }

    const getGeneralInfo = () => {
      const apiPath = inject('apiPath');
      fetch(`${apiPath}generalInfo?token=${store.state.usertoken}`).then((res) => res.json()).then((response) => {
        localStorage.setItem("tenantlist",response.usertenants)
        if (response.project) {
          localStorage.setItem('generalInfo', JSON.stringify(response));
          localStorage.setItem('projectName', response.project.name);
          localStorage.setItem("currenttenant",response.project.tenant)
        } else {
          console.log('Looks like there was a problem: \n', response.error);
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    };


    getGeneralInfo();
    return {
    };
  },
  computed: {
    hideHeader() {
      if (this.$route.name === 'Content_edit' || this.$store.state.movePlace || this.$route.name === '403' || this.$route.name === 'login' || this.$route.name === 'reset-password' || this.$route.name === 'Page') {
        return false;
      }
      return true;
    },
    hideBottom() {
      if (this.$route.name === 'Home' || this.$route.name === 'Content_edit' || this.$route.name === 'login' || this.$route.name === 'reset-password' || this.$route.name === 'Page') {
        // if (this.$route.name === 'Home' || this.$route.name === 'Content_edit' || this.$route.name === 'Article' && this.$store.state.edit || this.$route.name === 'login' || this.$route.name === 'reset-password') {
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
// LARGE SCREENS
// @media screen and (min-width: 27!01px) and (max-width: 3840px){
//   html{
//     font-size: 36px;
//   }
// }

// DESKTOP
// @media screen and (min-width: 1025px) and (max-width: 27!00px){
@media screen and (min-width: 1025px) {
  html {
    font-size: 21px;
  }
}

// LAPTOP
@media screen and (min-width: 901px) and (max-width: 1024px) {
  html {
    font-size: 19px;
  }
}

// TABLET
@media screen and (min-width: 481px) and (max-width: 900px) {
  html {
    font-size: 18px;
  }
}

// MOBILE
@media screen and (max-width: 480px),
screen and (max-height: 480px) and (orientation: landscape) {
  html {
    font-size: 17px;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  html {
    font-size: 17px;
  }
}

p {
  line-height: 1.41rem;
}

h1 {
  font-size: 1.77rem;
  line-height: 2.23rem;
}

h2 {
  font-size: 1.53rem;
  line-height: 2rem;
  font-weight: 600;
}

h3 {
  font-weight: 500;
  font-size: 1.29rem;
  line-height: 1.76rem;
}

.functional {
  font-size: 1rem;
  line-height: 1.23rem;
}

.caption {
  font-size: 0.82rem;
  line-height: 1.18rem;
}

.tileTitle {
  font-size: 1.06rem;
  line-height: 1.29rem;
}

.tileSubtext {
  font-size: 0.76rem;
  line-height: 0.94rem;
}

// DISABLE THE RELOAD ON ANDROID
html,
body {
  overscroll-behavior: none;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
}

textarea:focus::-webkit-input-placeholder {
  opacity: 0;
}

#text_to_style {
  p {
    margin: 0px;
  }

  p,
  h1,
  h2,
  h3 {
    width: inherit;
    word-wrap: break-word;
  }
}

// THIS BIT OF STYLING IS FOR BOOK PAGE
.toggle {
  *:has(img) {
    overflow: auto;
    width: calc(100% - 0px);
    word-wrap: break-word;
    box-sizing: border-box;
  }
}

.breadcrumb_popup {
  position: fixed;
  width: 456px;
  max-width: calc(100% - 32px);
  background-color: #fff;
  z-index: 3;
  padding: 16px 32px 32px 32px;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  top: 50%;
  left: 50%;
  margin-left: -228px;
  transform: translateY(-50%);
  box-sizing: border-box;
  text-align: center;

  .breadcrumbtree {
    position: relative;
    background-color: #f8f8f8;
    left: 0px;
    right: 32px;
    border-radius: 8px;
    padding: 1px 0;
    text-align: left;
    max-height: calc(80% - 150px);
  }

  @media screen and (max-width: 599px) and (orientation: portrait),
  (max-height: 500px) and (orientation: landscape) {
    padding: 16px 32px 16px 32px;
    width: calc(100% - 32px);
    left: 16px;
    margin-left: auto;
  }

  p {
    margin: 0px 0px 16px 0px;
    color: #252525;
    font-weight: 500;
    text-align: left;
  }

  .button {
    margin-top: 32px;
    width: 50%;
    max-width: 160px;
  }
}

//vue-notification-template
.notifications {
  width: 200px;
  position: fixed;
  right: 32px;
  top: 104px;
  z-index: 2;

  >div {
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid rgb(9, 159, 69);
    height: 80px;
    line-height: 80px;
    width: 100%;
    text-align: center;
    position: absolute;
    box-sizing: border-box;
    box-shadow: 0px 6px 20px 1px rgba(37, 37, 37, 0.04);
  }

  .notification_loading {
    padding-left: 10px;

    .rotate {
      height: 32px;
      width: 32px;
      display: inline-block;
      background-image: url(../public/img/spinner2.png);
      background-size: contain;
      background-position: center;
      animation: rotate 1s linear infinite;
      opacity: 0.3;
      position: relative;
      top: 9px;
      left: -15px;
    }

    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
  }

  .notification_saved {
    background-repeat: no-repeat;
    background-image: url(../public/img/saved.svg);
    background-position: 16px center;
    padding-left: 10px;
  }

  .notification_error {
    background-repeat: no-repeat;
    background-image: url(../public/img/err.svg);
    background-position: 16px center;
    padding-left: 20px;
    border: 1px solid #F44336;
  }
}

.expired {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -150px;
  width: 300px;
  height: 300px;
}

.notification-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  padding-bottom: 150px;
}

.saved {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: rgba(120, 120, 120, .2);
  z-index: 2;
  line-height: 100%;
  margin: auto;

  >div {
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: #fff;
    font-size: 16px;
    height: 100%;
    width: 100%;
    background-image: url(../public/img/checkmark.gif);
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    margin: auto;
    text-align: center;
  }
}

#app {
  height: 100%;
  margin: 0 auto;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
}

.main_v8 {
  background-color: #f2f2f2;
  position: absolute;
  bottom: 72px;
  top: 64px;
  overflow: scroll;
  box-sizing: border-box;
  width: inherit;
  overflow-x: hidden;
  overflow-y: overlay;
  right: 0px;
  left: 0px;
  scrollbar-gutter: auto;

  // @media screen and (min-width: 27!01px) and (max-width: 3840px){
  //   padding: 0 100px;
  //   top: 150px;
  //   bottom: 169px;
  // }
  // DESKTOP
  // @media screen and (min-width: 1025px) and (max-width: 27!00px){
  @media screen and (min-width: 1025px) {
    padding: 0 50px;
  }

  // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px) {
    padding: 0 40px;
  }

  // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px) {
    padding: 0 16px;
    bottom: 64px;
  }

  // MOBILE
  @media screen and (max-width: 480px),
  screen and (max-height: 480px) and (orientation: landscape) {
    padding: 0 15px;
    bottom: 64px;
    top: 44px;

    .page_header {
      margin-top: 40px;
      font-weight: 600;
    }
  }

  .page_header {
    margin-top: 50px;
    font-weight: 600;
    flex-basis: 100%;
    margin-bottom: 0px;
  }
}

.menu_shadow {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.dropdown-enter-active,
.dropdown-leave-active,
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.dropdown-enter-from {
  margin-top: -50px;
}

.dropdown-leave-to {
  transform: scale(0.5);
  opacity: 0.2;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  margin-bottom: -120px;
}

img {
  position: relative;
  display: inline-block;
}

img[alt]:after {
  border: dashed #086b43;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  background-size: 50% 50%;
  background-image: url(../public/img/processing-image.svg);
  background-repeat: no-repeat;
  background-position: center;
  content: attr(alt);
}

@media screen and (min-width: 481px) and (max-width: 900px) {
  .main_v8 {
    top: 52px;
    bottom: 64px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800');

body {
  font-family: 'Inter', sans-serif !important;
  color: #252525;
  margin: 0px;
  box-sizing: border-box;
}

// SCREEN LOCK
@media screen and (max-height: 480px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .main_v8 {
    top: 56px;
    bottom: 66px;

    h2 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(2em);
  opacity: 0;
}
</style>
