<template>
    <div v-if="tenantselectshow" class="disabled_warning">
      <h3>Select a Server</h3>
      <p class="functional">
        You have multiple servers<br/><br/>
        Select the server from the list that you would like to use for this session.
      </p>
      <div class="btns">
        <btn class="button secondary" v-text="'Cancel'" @click="$emit('tenantselectcancel')"></btn>
      </div><br>
      <select v-model="selected" class="drop" @change="HandleChange">
        <option v-for="t in tenantlist" :value="t">{{ t }}</option>
      </select>
    </div>
  </template>
  
  <script setup>
    import { ref } from 'vue';
    import btn from '../buttons/button.vue';
    const props = defineProps({tenantselectshow: Boolean, currenttenant: String, tenantlist: Array});
    const emit = defineEmits(['tenantselected','tenantselectcancel']);
    const selected = ref(props.currenttenant)

    function HandleChange() {
      console.log("User Selected Server:",selected.value)
      emit("tenantselected",selected.value)
    }
  </script>
  
  <style lang="scss" scoped>
  .drop {
    padding:20px;
    font-size: 20px;
    width:96%;
  }
  .disabled_warning{
    width: calc(100% - 32px);
    max-width: 540px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px 32px 145px;
    border-radius: 16px;
    background-color: #fff;
    z-index: 3;
    box-sizing: border-box;
    h3{
      margin: 0 0 32px 0;
    }
    p{
      margin: 0 auto;
      color: #787878
    }
    .btns{
      text-align: center;
      position: absolute;
      bottom: 32px;
      width: 100%;
      left: 0px;
      display: flex;
      padding: 0 32px;
      box-sizing: border-box;
      .button{
        padding: 0;
      }
      :first-child{
        width: 125px;
        margin-right: 16px;
      }
      :last-child{
        flex: 1;
      }
    }
  }
  @media screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 500px) and (orientation: landscape) {
    .disabled_warning{
      padding: 16px 16px 115px;
    }
  }
  </style>