<template>
  <editor v-model="data"
          api-key="no-api-key"
          @paste="TMCEPaste"
          :init="{
                height: '100%',
                menubar: 'edit view insert format tools table help',
                branding: false,
                promotion: false,
                content_css: '/mce_editor.css',
                highlight_on_focus: false,
                visual: false,
                visual_table_class: '',
                focus_outline_on_click: false,
                object_resizing: true,
                content_style: `@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800'); @import url('/mce_editor.css'); body { font-family: Inter; }; ol,ul { padding-left: 20px;};`,
                paste_preprocess: function (pl, o) {
                  o.content = smartPaste(o.content);
                },
                automatic_uploads: true,
                images_upload_handler: tinymceImageUploadHandler,
                images_reuse_filename: true,
                menubar: 'file edit view insert format tools table help',
                menu: {
                    file: {
                        title: 'File',
                        items: 'preview print'
                    },
                    edit: {
                        title: 'Edit',
                        items: 'undo redo | cut copy paste | selectall | searchreplace'
                    },
                    view: {
                        title: 'View',
                        items: 'code | visualaid visualchars visualblocks | preview fullscreen'
                    },
                    insert: {
                        title: 'Insert',
                        items: 'mceCustomImage link codesample | charmap emoticons hr | insertdatetime'
                    },
                    format: {
                        title: 'Format',
                        items: 'bold italic underline strikethrough superscript subscript | styles blocks fontfamily fontsize align | removeformat'
                    },
                    tools: {
                        title: 'Tools',
                        items: 'spellchecker spellcheckerlanguage | wordcount'
                    },
                    table: {
                        title: 'Table',
                        items: 'inserttable | cell row column | tableprops deletetable'
                    }
                },
                codesample_languages: [
                    { text: 'HTML/XML', value: 'markup' },
                    { text: 'CSS', value: 'css' },
                    { text: 'C-like', value: 'clike' },
                    { text: 'JavaScript', value: 'javascript' },
                    { text: 'Bash', value: 'bash' },
                    { text: 'BASIC', value: 'basic' },
                    { text: 'Batch', value: 'batch' },
                    { text: 'C', value: 'c' },
                    { text: 'C#', value: 'csharp' },
                    { text: 'C++', value: 'cpp' },
                    { text: 'COBOL', value: 'cobol' },
                    { text: 'DNS Zone', value: 'dns-zone-file' },
                    { text: 'Docker', value: 'docker' },
                    { text: 'EditorConfig', value: 'editorconfig' },
                    { text: 'Excel Formula', value: 'excel-formula' },
                    { text: 'FORTRAN', value: 'fortran' },
                    { text: 'G-code', value: 'gcode' },
                    { text: 'Gettext', value: 'gettext' },
                    { text: 'GraphQL', value: 'graphql' },
                    { text: 'Javadoc', value: 'javadoclike' },
                    { text: 'JSON', value: 'json' },
                    { text: 'LaTeX', value: 'latex' },
                    { text: 'LISP', value: 'lisp' },
                    { text: 'Log files', value: 'log' },
                    { text: 'Lua', value: 'lua' },
                    { text: 'Makefile', value: 'makefile' },
                    { text: 'Markdown', value: 'markdown' },
                    { text: 'Nginx', value: 'nginx' },
                    { text: 'Pascal', value: 'pascal' },
                    { text: 'Perl', value: 'perl' },
                    { text: 'PHP', value: 'php' },
                    { text: 'PlantUML', value: 'plant-uml' },
                    { text: 'PL/SQL', value: 'plsql' },
                    { text: 'PowerQuery', value: 'powerquery' },
                    { text: 'PowerShell', value: 'powershell' },
                    { text: 'Python', value: 'python' },
                    { text: 'Q', value: 'q' },
                    { text: 'QML', value: 'qml' },
                    { text: 'JSX', value: 'jsx' },
                    { text: 'reST', value: 'rest' },
                    { text: 'Ruby', value: 'ruby' },
                    { text: 'SQL', value: 'sql' },
                    { text: 'TypoScript', value: 'typoscript' },
                    { text: 'VB.NET', value: 'vbnet' },
                    { text: 'Visual Basic', value: 'visual-basic' },
                    { text: 'YAML', value: 'yaml' }
                ],
                plugins: [
                  'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                  'searchreplace', 'visualblocks', 'fullscreen', 'insertdatetime',
                  'table', 'help', 'wordcount', 'codesample', 'pagebreak',
                  'nonbreaking', 'advlist', 'emoticons'
                ],
                // toolbar: [
                //   'undo redo | formatselect |',
                //   'bold italic forecolor | alignleft aligncenter alignright alignjustify |',
                //   'bullist numlist outdent indent | customimage codesample |',
                //   'table | searchreplace find replaceall'
                // ].join(' '),
                toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | customimage link | codesample',
                setup: function (current_editor) {
                  editorSetup(current_editor);
                },
          }"
  />

</template>

<script setup>
import {useStore} from "vuex";
import Editor from '@tinymce/tinymce-vue';
import {ref, defineProps, defineEmits, watch, inject} from 'vue';
import '../../../assets/js/tinymce/tinymce.min';
import '../../../assets/js/tinymce/themes/silver/theme.min.js'
import '../../../assets/js/tinymce/icons/default'
import '../../../assets/js/tinymce/skins/ui/oxide/skin.min.css'
import '../../../assets/js/tinymce/skins/content/default/content.min.css'
import '../../../assets/js/tinymce/plugins/lists';
import '../../../assets/js/tinymce/plugins/link';
import '../../../assets/js/tinymce/plugins/image';
import '../../../assets/js/tinymce/plugins/charmap';
import '../../../assets/js/tinymce/plugins/preview';
import '../../../assets/js/tinymce/plugins/anchor';
import '../../../assets/js/tinymce/plugins/searchreplace';
import '../../../assets/js/tinymce/plugins/visualblocks';
import '../../../assets/js/tinymce/plugins/fullscreen';
import '../../../assets/js/tinymce/plugins/insertdatetime';
import '../../../assets/js/tinymce/plugins/table';
import '../../../assets/js/tinymce/plugins/help';
import '../../../assets/js/tinymce/plugins/wordcount';
import '../../../assets/js/tinymce/plugins/codesample';
import '../../../assets/js/tinymce/plugins/pagebreak';
import '../../../assets/js/tinymce/plugins/nonbreaking';
//import '../../../assets/js/tinymce/plugins/paste';
//import '../../../assets/js/tinymce/plugins/imagetools';
import '../../../assets/js/tinymce/plugins/advlist';
import '../../../assets/js/tinymce/plugins/emoticons';

const store = useStore();

const apiPath = inject("apiPath");

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  }
});

console.log(props);
let data = ref(props.modelValue);
let html = ref('<div style="' +
    '                        display: flex;' +
    '                        flex-direction: column;' +
    '                        align-items: center;' +
    '                        padding: 20px;' +
    '                        margin: 16px;' +
    '                        border: 2px dashed #bcbcbc;' +
    '                        border-radius: 8px;' +
    '                        min-height: 200px;' +
    '                        background: white;' +
    '                    ">' +
    '                        ' +
    '                        <div style="' +
    '                            width: 128px;' +
    '                            height: 128px;' +
    '                            margin: 20px;' +
    '                            display: flex;' +
    '                            align-items: center;' +
    '                            justify-content: center;' +
    '                        ">' +
    '                            <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '                                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.8147 25.332C27.7874 25.332 25.3333 27.7862 25.3333 30.8135V97.1839C25.3333 100.044 27.5241 102.393 30.3194 102.643L80.4513 50.5582C81.2054 49.7747 82.2459 49.332 83.3333 49.332C84.4206 49.332 85.4611 49.7747 86.2152 50.5582L102.667 67.6505V30.8135C102.667 27.7862 100.212 25.332 97.1851 25.332H30.8147ZM110.667 77.567V30.8135C110.667 23.3679 104.631 17.332 97.1851 17.332H30.8147C23.3691 17.332 17.3333 23.3679 17.3333 30.8135V97.1839C17.3333 104.629 23.3691 110.665 30.8147 110.665H31.9924C31.9971 110.665 32.0018 110.665 32.0064 110.665H97.1851C104.631 110.665 110.667 104.629 110.667 97.1839V77.581C110.667 77.5763 110.667 77.5716 110.667 77.567ZM102.667 79.1867L83.3333 59.1001L41.4017 102.665H97.1851C100.212 102.665 102.667 100.211 102.667 97.1839V79.1867ZM45.3333 41.332C43.1241 41.332 41.3333 43.1229 41.3333 45.332C41.3333 47.5412 43.1241 49.332 45.3333 49.332C47.5424 49.332 49.3333 47.5412 49.3333 45.332C49.3333 43.1229 47.5424 41.332 45.3333 41.332ZM33.3333 45.332C33.3333 38.7046 38.7058 33.332 45.3333 33.332C51.9607 33.332 57.3333 38.7046 57.3333 45.332C57.3333 51.9594 51.9607 57.332 45.3333 57.332C38.7058 57.332 33.3333 51.9594 33.3333 45.332Z" fill="#BCBCBC"/>' +
    '                            </svg>' +
    '                        </div>' +
    '                        ' +
    '' +
    '                        ' +
    '                        <div style="' +
    '                            color: #444444;' +
    '                            font-size: 16px;' +
    '                            margin: 10px 0;' +
    '                        ">Drag and drop an image file to upload</div>' +
    '                        ' +
    '' +
    '                        ' +
    '                        <button type="button" style="' +
    '                            background-color: white;' +
    '                            border: 2px solid #bcbcbc;' +
    '                            border-radius: 4px;' +
    '                            padding: 8px 24px;' +
    '                            color: #444444;' +
    '                            cursor: pointer;' +
    '                            font-size: 14px;' +
    '                            margin-top: 10px;' +
    '                        " onmouseover="this.style.borderColor=\'#444444\'" ' +
    '                          onmouseout="this.style.borderColor=\'#bcbcbc\'"' +
    '                        >Select File</button>' +
    '                        ' +
    '' +
    '                        ' +
    '                        <input type="file" id="imageInput" style="display: none" accept="image/*">' +
    '                        ' +
    '                    </div>');

const emit = defineEmits(['update:modelValue']);
watch(() => props.modelValue, (newValue) => {
  data.value = newValue;
});
watch(() => data.value, (value) => {
  emit('update:modelValue', value);
});

function editorSetup(current_editor) {
  current_editor.on('init', function () {
    current_editor.shortcuts.add('meta+f', 'Find', 'SearchReplace');
    current_editor.shortcuts.add('meta+h', 'Replace', 'SearchReplace');
  });
  current_editor.addCommand('mceCustomImage', function () {
    current_editor.windowManager.open({
      title: 'Upload an image',
      size: 'medium',
      body: {
        type: 'panel',
        items: [{
          type: 'htmlpanel',
          html: html.value
        }]
      },
      buttons: []
    });
    setTimeout(() => {
      const dropzone = document.querySelector('div[style*="border: 2px dashed"]');
      const imageInput = document.querySelector('#imageInput');
      const selectButton = dropzone.querySelector('button');

      const handleFile = (file) => {
        if (file && file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = function () {
            current_editor.insertContent(`<img src="${this.result}" alt=""/>`);
            current_editor.windowManager.close();
          };
          reader.readAsDataURL(file);
        }
      };

      dropzone.addEventListener('dragover', (e) => {
        e.preventDefault();
        dropzone.style.borderColor = '#444444';
      });

      dropzone.addEventListener('dragleave', () => {
        dropzone.style.borderColor = '#bcbcbc';
      });

      dropzone.addEventListener('drop', (e) => {
        e.preventDefault();
        dropzone.style.borderColor = '#bcbcbc';
        const file = e.dataTransfer.files[0];
        handleFile(file);
      });

      selectButton.addEventListener('click', () => {
        imageInput.click();
      });

      imageInput.addEventListener('change', (e) => {
        const file = e.target.files[0];
        handleFile(file);
      });
    }, 100);
  });

  current_editor.ui.registry.addButton('customimage', {
    icon: 'image',
    tooltip: 'Upload an image',
    onAction: function () {
      current_editor.execCommand('mceCustomImage');
    }
  });

  current_editor.ui.registry.addMenuItem('mceCustomImage', {
    text: 'Upload Image',
    icon: 'image',
    onAction: function () {
      current_editor.execCommand('mceCustomImage');
    }
  });
}

function TMCEPaste(e) {
  setTimeout(() => {
    let imgTags = e.currentTarget.getElementsByTagName('img');
    for (let imgTag of imgTags) {
      if (!imgTag.outerHTML.includes('<img width="') && imgTag.src.includes('data:image/')) {
        imgTag.outerHTML = imgTag.outerHTML.replace('<img', '<img width="' + imgTag.width + '" height="' + imgTag.height + '"'
            + 'style="width:'+imgTag.width+'px;height:'+imgTag.height+'px;"'
        );
      }
    }
  }, 300);
}
function smartPaste(str) {
  str = str.replaceAll(/(?:class|className)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/g, ``);
  str = str.replaceAll(/(?:id|className)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/g, ``);
  if (str.includes('https://www.youtube.com/') || str.includes('https://youtube.com/') || str.includes('https://youtu.be/')) {
    str = str.replaceAll(/(?<!["'`])((?:http|https|ftp):\/\/\S+)(?!["'`])/gm, `<a href='$&' target.value='_blank' rel='nofollow'>$&</a>`);
  }
  str = str.replaceAll(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/gm, ``);
  str = str.replaceAll(/(?:<script[^>]*)(?:(?:\/>)|(?:>.*?<\/script>))/gm, ``);
  str = str.replaceAll(/(?:<style[^>]*)(?:(?:\/>)|(?:>.*?<\/style>))/gm, ``);
  str = str.replaceAll(/\bwidth="(\d+)"/g, ``);
  str = str.replaceAll(/\bheight="(\d+)"/g, ``);
  str = str.replaceAll(/position:(fixed)/gm, ``);
  str = str.replaceAll(/position:( fixed)/gm, ``);
  return str;
}

// function OLDtinymceImageUploadHandlerOLD(blobInfo, success, failure, progress) {
//   const reader = new FileReader();
//
//   reader.onload = function() {
//     const base64String = reader.result;
//     success(base64String);
//   };
//
//   reader.onerror = function() {
//     failure('Error while reading a file');
//   };
//
//   reader.readAsDataURL(blobInfo.blob());
// }

function extractSubstringWithSearch(inputString, search) {
  let startIndex = 0;

  while (startIndex < inputString.length) {
    const start = inputString.indexOf('"', startIndex);
    if (start === -1) break;

    const end = inputString.indexOf('"', start + 1);
    if (end === -1) break;

    const substring = inputString.slice(start + 1, end);
    if (substring.includes(search)) {
      return substring;
    }

    startIndex = end + 1;
  }

  return null;
}

async function tinymceImageUploadHandler(blobInfo, success) {
    let uploadUrl = await fetch(`${apiPath}cloudflareGetUploadLinkImage?token=${store.state.usertoken}&tenant=${localStorage.getItem("currenttenant")}`
    ).then((res) => res.text()).then((response) => {
      return {'result': response};
    }).catch((error) => {
      return {'result': false, 'message': `Looks like there was a problem with fetching data from backend. Error: \n`, error};
    });
    let response;
    if (uploadUrl.result) {
      response = await uploadFile(uploadUrl.result, blobInfo.blob());
    } else {
      response = uploadUrl;
    }
    if (response.result) {
      let url = response.result;
      //success({'location':url});
      console.log('check', typeof url, url);
      success(url);
      data.value = data.value.replaceAll(extractSubstringWithSearch(data.value, blobInfo.base64()), url); //todo find a better way
    } else {
      console.log('error');
      alert(response.message);
    }
    console.log('data2', data.value);
}

async function uploadFile(uploadURL, file) {
  const formData = new FormData();
  formData.append('tenant', localStorage.getItem("currenttenant"));
  formData.append('file', file);
  return await fetch(uploadURL, {
    method: 'POST',
    body: formData,
  }).then((response)=>response.json()).then((response) => {
    if (response.result === null) {
      return {'result': false, 'message': 'Looks like there was a problem: \n' + response.errors.message};
    }
    return {'result': response.result.variants[0].replace('/section', '/public').replace('/thumb', '/public')};
  }).catch((error) => {
    console.log('Cloudflare error', error);
    return {'result': false, 'message': 'Wrong format or Cloudflare is down'};
  });
}

</script>

<style lang="scss" scoped>

</style>