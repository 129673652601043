<template>
  <div v-bind="getRootProps()"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
    :class="{ 'active-dropzone': active }"
    class="dropzone"
    v-if='!isUploading'>
    Upload photo
      <input v-bind="getInputProps()"  id="dropzoneFile" class="dropzoneFile" accept=".jpg, .jpeg, .png, .webP, .gif"/>
  </div>
  <p class="uploading" style="display: none;" v-if='isUploading'>Upload Image</p>
  <div v-if='isUploading' class='gauge'>
    <highcharts :p="percentComplete" :h="ht"></highcharts>
  </div>
  
</template>

<script>
/* eslint-disable */
import {inject, ref} from 'vue';
import { useDropzone } from 'vue3-dropzone';
import MapChart from '../highcharts/solidgauge';
import {useStore} from "vuex";

export default {
  name: 'DropZone',
  inject: ['apiPath'],
  components: {
    highcharts: MapChart,
  },
  props: ['docid', 'n','index','height'],
  emits: ['uploadCompleted','uploadStarted'],
  setup(props,context) {
    const store = useStore();
    const apiPath = inject('apiPath');
    const percentComplete = ref(0.0);
    const isUploading = ref(false);
    let ht = ref(0);
    ht = props.height;
    // console.log("Height set in cloudflare comp" + ht);

    async function getOneTimeUploadUrl() {
      let response = await fetch(`${apiPath}cloudflareGetUploadLinkImage?token=${store.state.usertoken}&tenant=${localStorage.getItem("currenttenant")}`);
      return await response.text();
    }
    function progressHandler(event) {
      let bytes = event.loaded;
      let showbytes = 0;
      let units = '';
      let total = 0;
      let percent = 0;
      if (bytes > 1000) {
        showbytes = bytes / 1000;
        units = 'KB';
      }
      if (bytes > 1000000) {
        showbytes = bytes / 1000000;
        units = 'MB';
      }
      const loaded = `${showbytes.toFixed(2)} ${units}`;

      bytes = event.total;
      showbytes = 0;
      units = '';
      if (bytes > 1000) {
        showbytes = bytes / 1000;
        units = 'KB';
      }
      if (bytes > 1000000) {
        showbytes = bytes / 1000000;
        units = 'MB';
      }
      total = `${showbytes.toFixed(2)} ${units}`;
      percent = (event.loaded / event.total) * 100;
      percentComplete.value = Math.round(percent);
    }

    const completeHandler = (event) => {
      const data = event.target.responseText;
      isUploading.value = false;
      // context.emit("uploadCompleted",props.index);
    }

    function errorHandler(event) {
      context.emit("uploadCompleted",-1);
    }

    function abortHandler(event) {
      context.emit("uploadCompleted",-2);
    }

    async function uploadFile(files) {
      const file = files[0];
      const formdata = new FormData();
      const ajax = new XMLHttpRequest();
      const doctype = 'posts';
      if (doctype == null) return false;
      const uploadURL = await getOneTimeUploadUrl();
      formdata.append('file', file);
      ajax.upload.addEventListener('progress', progressHandler, false);
      ajax.addEventListener('load', completeHandler, false);
      ajax.addEventListener('error', errorHandler, false);
      ajax.addEventListener('abort', abortHandler, false);
      ajax.open('POST', uploadURL);
      ajax.send(formdata);
      // console.log(file, uploadURL);
      context.emit("uploadStarted",uploadURL,props.index);
      return true;
    }

    function onDrop(acceptFiles, rejectReasons) {
      uploadFile(acceptFiles);
      isUploading.value = true;
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };

    return {
      active,
      ht,
      percentComplete,
      isUploading,
      progressHandler,
      completeHandler,
      toggleActive,
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>

<style lang="scss" scoped>

*{
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
}
.delete{
    height: 32px;
    width: 32px;
    position: absolute;
    background-color: #fff;
    border-radius: 16px;
    top: 5px;
    right: 5px;
    background-image: url(../../../public/img/close_gray.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
  }
.gauge {
  position: absolute;
  border-radius: 16px;
  //border: dashed 2px green;
  width: 100%;
  height: 100%;
}
.dropzone {
    text-align: center;
    display: inline-block;
    height: 45px;
    line-height: 45px;
    font-size: 1rem;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    background-color: #006C3E;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    border-color: #006C3E;
    transition: all 0.1s;
    padding: 0 24px;
}
.tag{
  .dropzone{
    background-image: url(../../../public/img/library/uploader.svg), url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(0, 108, 62, 0.15)' stroke-width='4' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    position: relative;
    background-size: 65px, 100%;
    background-position: center 37px, 0px;
    width: 100%;
    height: 191px;
    flex: 1;
    left: auto;
  }
}
.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #41b883;
  label {
    background-color: #fff;
    color: #41b883;
  }
}
</style>
