<template>
  <div v-if="node?.parentid === 0 || store.state.permissionsNodeShowState[node?.parentid]" :style="{'margin-left': `calc(0px + ${depth * leftMargin}px`}" class="tree_item">
    <transition-group v-if="node?.children?.length > 0">
      <span class="unroll" v-if="plus" @click="plus = !plus">+</span>
      <span class="unroll" v-if="!plus" @click="plus = !plus">-</span>
    </transition-group>
    <p v-if="width <= 480" @touchstart="e => e.target.classList.add('active')" @mousedown="e => e.target.classList.add('active')" @touchend="e => e.target.classList.remove('active')" @mouseup="e => e.target.classList.remove('active')">{{ node?.name }}</p>
    <p v-else>{{ node?.name }}</p>
    <div class="individual_Permissions">
      <label>
        <input type="checkbox" v-model="nodePermissions.inherit"/>
        <i class="checkmark"></i>
      </label>
      <label>
        <input type="checkbox" v-model="nodePermissions.view"/>
        <i class="checkmark"></i>
      </label>
      <label>
        <input type="checkbox" v-model="nodePermissions.edit"/>
        <i class="checkmark"></i>
      </label>
      <label>
        <input type="checkbox" v-model="nodePermissions.add"/>
        <i class="checkmark"></i>
      </label>
      <label>
        <input type="checkbox" v-model="nodePermissions.delete"/>
        <i class="checkmark"></i>
      </label>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, toRefs, watch, nextTick, inject, onMounted} from 'vue';
import {useStore} from "vuex";

const apiPath = inject('apiPath');
const props = defineProps({ node: Object, depth: Number });
const { node } = toRefs(props);
let plus = ref(false);
let width = ref(window.innerWidth);
let leftMargin = ref(8);

onMounted(() => {
  window.addEventListener('resize', () => {
    width.value = window.innerWidth;
    if (width.value <= 480) {
      leftMargin.value = 8;
    } else {
      leftMargin.value = 16;
    }
  });
});

let fillProcessActive = ref(false);
let nodePermissions = ref({});
setNodePermissions();

const store = useStore();
store.state.permissionsByLocationId[node.value.id] = node.value.permissions?.length > 0 ? node.value.permissions : [];
if (node?.value.parentid !== 0) {
  store.state.permissionsNodeShowState[node.value.id] = store.state.permissionsNodeShowState[node?.value.parentid] ? !plus.value : store.state.permissionsNodeShowState[node?.value.parentid];
} else {
  store.state.permissionsNodeShowState[node.value.id] = !plus.value;
}

async function setNodePermissions() {
  fillProcessActive.value = true;
  await nextTick();
  nodePermissions.value.inherit = !node.value.permissions?.includes('disinherit') && !node.value.permissions?.includes('view') && !node.value.permissions?.includes('edit') && !node.value.permissions?.includes('add') && !node.value.permissions?.includes('delete');
  nodePermissions.value.view = node.value.permissions?.includes('view');
  nodePermissions.value.edit = node.value.permissions?.includes('edit');
  nodePermissions.value.add = node.value.permissions?.includes('add');
  nodePermissions.value.delete = node.value.permissions?.includes('delete');
  await nextTick();
  fillProcessActive.value = false;
}

watch(() => props.node, (newValue) =>
{
  node.value = newValue;
  setNodePermissions();
  plus.value = false;
  store.state.permissionsNodeShowState[node.value.id] = true;
}, { deep: true });

watch(() => plus.value, (newValue) =>
{
  if (node?.value.parentid !== 0) {
    store.state.permissionsNodeShowState[node.value.id] = store.state.permissionsNodeShowState[node?.value.parentid] ? !newValue : store.state.permissionsNodeShowState[node?.value.parentid];
  } else {
    store.state.permissionsNodeShowState[node.value.id] = !newValue;
  }
});
watch(() => store.state.permissionsNodeShowState[node.value.parentid], (newValue) =>
{
  store.state.permissionsNodeShowState[node.value.id] = !newValue ? newValue : plus.value;
  plus.value = !newValue ? !newValue : false;
});

watch(() => nodePermissions.value.inherit, (newValue, oldValue) => {
    if (newValue && !oldValue) {
      nodePermissions.value.view = false;
      nodePermissions.value.edit = false;
      nodePermissions.value.add = false;
      nodePermissions.value.delete = false;
      if (!fillProcessActive.value) {
        sendFetchPermission('deleteByFilter', 'disinherit');
      }
    } else if (oldValue && !newValue) {
      if (!fillProcessActive.value) {
        sendFetchPermission('add', 'disinherit');
      }
    }
});

watch(() => nodePermissions.value.view, (newValue, oldValue) => {
    if (newValue && !oldValue) {
      nodePermissions.value.inherit = false;
      if (!fillProcessActive.value) {
        sendFetchPermission('add', 'view');
      }
    } else if (oldValue && !newValue) {
      if (!fillProcessActive.value) {
        sendFetchPermission('deleteByFilter', 'view');
      }
    }
});

watch(() => nodePermissions.value.edit, (newValue, oldValue) => {
    if (newValue && !oldValue) {
      nodePermissions.value.inherit = false;
      if (!fillProcessActive.value) {
        sendFetchPermission('add', 'edit');
      }
    } else if (oldValue && !newValue) {
      if (!fillProcessActive.value) {
        sendFetchPermission('deleteByFilter', 'edit');
      }
    }
});

watch(() => nodePermissions.value.add, (newValue, oldValue) => {
    if (newValue && !oldValue) {
      nodePermissions.value.inherit = false;
      if (!fillProcessActive.value) {
        sendFetchPermission('add', 'add');
      }
    } else if (oldValue && !newValue) {
      if (!fillProcessActive.value) {
        sendFetchPermission('deleteByFilter', 'add');
      }
    }
});

watch(() => nodePermissions.value.delete, (newValue, oldValue) => {
    if (newValue && !oldValue) {
      nodePermissions.value.inherit = false;
      if (!fillProcessActive.value) {
        sendFetchPermission('add', 'delete');
      }
    } else if (oldValue && !newValue) {
      if (!fillProcessActive.value) {
        sendFetchPermission('deleteByFilter', 'delete');
      }
    }
});

watch(() => store.state.allPermissionsInherit, async (newValue) => {
  fillProcessActive.value = true;
  await nextTick();
  nodePermissions.value.inherit = newValue;
  await nextTick();
  fillProcessActive.value = false;
});
watch(() => store.state.allPermissionsView, async (newValue) => {
  fillProcessActive.value = true;
  await nextTick();
  nodePermissions.value.view = newValue;
  await nextTick();
  fillProcessActive.value = false;
});
watch(() => store.state.allPermissionsEdit, async (newValue) => {
  fillProcessActive.value = true;
  await nextTick();
  nodePermissions.value.edit = newValue;
  await nextTick();
  fillProcessActive.value = false;
});
watch(() => store.state.allPermissionsAdd, async (newValue) => {
  fillProcessActive.value = true;
  await nextTick();
  nodePermissions.value.add = newValue;
  await nextTick();
  fillProcessActive.value = false;
});
watch(() => store.state.allPermissionsDelete, async (newValue) => {
  fillProcessActive.value = true;
  await nextTick();
  nodePermissions.value.delete = newValue;
  await nextTick();
  fillProcessActive.value = false;
});

function sendFetchPermission(action, permission, entity_type = 'location') {
  sendFetch(
      'entity/permission/' + action,
      {
        "group_id": store.state.permissionsGroupdId,
        "entity_type": entity_type,
        "entity_id": node.value.id,
        "permission": permission
      }
  );
}

async function sendFetch(url, data = null) {
  let formData = new FormData();
  formData.append('token', store.state.usertoken);
  formData.append('tenant',localStorage.getItem("currenttenant"))
  if (data !== null) {
    Object.keys(data).forEach(function(key) {
      formData.append(key, data[key]);
    });
  }
  return await fetch(`${apiPath}` + url, {
    method: 'POST',
    body: formData,
  }).then((res) => res.json()).then((response) => {
    if (response.result) {
      return response.result;
    } else {
      console.log('Looks like there was a problem: \n', response.error);
      if (response.error === 'Wrong token') {
        window.location.reload();
      }
      return false;
    }
  }).catch((error) => {
    console.log('Looks like there was a problem: \n', error);
    return false;
  });
}
</script>

<style lang="scss" scoped>
.tree_item{
  height: 36px;
  border-bottom: 1px solid #bcbcbc;
  box-sizing: border-box;
  padding-left: 10px;
  border-left: 2px solid #444;
  margin-left: 20px;
  display: flex;
  .unroll{
    display: flex;
    align-self: center;
    height: 36px;
    line-height: 33px;
    min-width: 20px;
    cursor: pointer;
    font-size: 1.1rem;
    justify-content: center;
    justify-self: center;
  }
  p{
    line-height: 35px;
    margin: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: all .2s ease-in-out;
    user-select: none;
  }
  p.active{
    text-overflow: unset;
    overflow: auto;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    padding-right: 8px;
  }
  .individual_Permissions{
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-left: auto;
    label{
      position: relative;
      width: 100px;
      text-indent: 20px;
      display: flex;
      align-self: center;
      justify-content: center;
      min-height: 100%;
      cursor: pointer;
      @media screen and (max-width: 740px) {
        width: 50px;
      }
      @media screen and (max-width: 480px) {
        width: 40px;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 2px solid #787878;
      box-sizing: border-box;
      border-radius: 6px;
      align-self: center;
    }
    label input:checked ~ .checkmark {
      background-color: #2884C7;
      border: 2px solid #2884C7;
    }
    .checkmark:after {
      content: "";
      display: none;
    }
    label input:checked ~ .checkmark:after {
      display: block;
    }
    label .checkmark:after {
      margin-left: -1px;
      width: 18px;
      height: 18px;
      border-radius: 6px;
      background-image: url(../../../public/img/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;
    }
  }
}
</style>