<template>
  <div class="menu_wrapper">
    <!-- <div class="mobile_search"> -->
      <!-- <img class="close"> -->
      <!-- <search></search> -->
    <!-- </div> -->
    <div class="section_one">
      <p class="functional">Your Location:</p>
      <!-- <a href="#" @click.prevent="$store.state.menu = false; $router.push('/select')">Change</a> -->
      <div class="map">
<!--        <breadcrumbtree @click.prevent="$store.state.menu = false; $router.push('/select')"></breadcrumbtree>-->
        <breadcrumbtree @click="$store.state.menu = false;"></breadcrumbtree>
      </div>
    </div>

    <!-- <div class="section_two"> -->
    <!--   <p class="functional">Apps for this location</p>-->
    <!--   <nav>-->
    <!--     <ul>-->
    <!--       <li v-for="(item, index) in menu" :key="index">-->
    <!--         <a v-if="item.isActive" class="functional" href="#" :style="{backgroundImage: item.filled_img}" @click.prevent="$store.state.menu = false; $router.push(item.route)">{{ item.name }}</a>-->
    <!--         <a v-if="!item.isActive" class="functional" href="#" :style="{backgroundImage: item.empty_img}" @click.prevent="$emit('disabledWindow')">{{item.name}}</a>-->
    <!--       </li>-->
    <!--     </ul>-->
    <!--   </nav>-->
    <!-- </div>-->

    <div class="section_three">
      <div class="user" style="cursor:default">
          <img v-if="store.state.user.metadata?.image" style='border-radius: 50%;' v-bind:src="store.state.user.metadata?.image">
          <div v-else>{{store.state.user.metadata?.name[0]}}{{store.state.user.metadata?.surname[0]}}</div>
          
          <!-- <svg v-else></svg> -->
          <p v-if="store.state.user">{{store.state.user.metadata?.name}} {{store.state.user.metadata?.surname}}
            <span v-if="tenantlist" @click="LoadTenantSwitcher" class="tenantswitch">{{ currentTenant }}</span>
          </p>
      </div>
      <nav>
        <ul>
<!--          <li><a href="#" v-if="isAuthenticated/* && this.$root.$refs.userrole.innerHTML=='Admin'*/" @click.prevent="showAdminPortal">Admin Portal</a></li>-->
          <li class="functional">
            <div v-if="$store.state.isEditable" @click="editMode" :class="[$store.state.edit ? 'edit_on' : 'edit']">Edit Mode</div>
          </li>
          <li v-if="true/*store.state.user.role.includes('bOpus_admin')*/" class="functional"><a href="#" @click.prevent="$store.state.menu = false; $router.push('/usermanagement')" class="settings">Settings</a></li>
<!--          <li v-else class="functional"><a href="#" @click.prevent="$emit('disabledWindow')" class="settings">Settings</a></li>-->
          <li class="functional"><router-link href="#" @click.prevent="$store.state.menu = false;" :to="{path:'/help'}" class="help">Help</router-link></li>
          <li class="functional"><a style='cursor:pointer;' @click="logout()" @click.prevent="$store.state.menu = false;" class="logout">Log out</a></li>
          <li class="functional"></li>
        </ul>
      </nav>
    </div>
    <center><div class="buildtag" >build: {{ build }}</div></center>
  </div>
  <tenantswitch
    :tenantselectshow=tenantswitchshow
    :tenantlist=tenantlist
    :currenttenant="currentTenant"
    @tenantselected=TenantSelected
    @tenantselectcancel = TenantSelectCancel
  />
</template>

<script setup>
  import { ref, inject } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import Session from "supertokens-web-js/recipe/session";
  import breadcrumbtree from '../breadcrumbtree/breadcrumbtree.vue';
  import tenantswitch from '../dialogs/tenantswitch.vue';

  const build = inject('build');
  const router = useRouter();
  const store = useStore();
  const tenantlist =  ref(localStorage.getItem("tenantlist"));
  const tenantswitchshow = ref(false);
  const disabledDialog = ref(store.state.disabledDialog);
  const currentTenant = ref(localStorage.getItem("currenttenant"))
  const menu = ref([
    {
      name: 'Library',
      route: '',
      empty_img: 'url(../../img/burger/machine/line.svg)',
      filled_img: 'url(../../img/burger/machine/filled.svg)',
      isActive: false,
    },
    {
      name: 'Stream',
      route: '',
      empty_img: 'url(../../img/burger/stream/line.svg)',
      filled_img: 'url(../../img/burger/stream/filled.svg)',
      isActive: false,
    },
    {
      name: 'PerformR',
      route: '',
      empty_img: 'url(../../img/burger/performR/line.svg)',
      filled_img: ' url(../../img/burger/performR/filled.svg)',
      isActive: false,
    },
    {
      name: 'Machine Alerts',
      route: '',
      empty_img: 'url(../../img/burger/alerts/line.svg)',
      filled_img: 'url(../../img/burger/alerts/filled.svg)',
      isActive: false,
    },
    {
      name: 'Analytics',
      route: '',
      empty_img: 'url(../../img/burger/analytics/line.svg)',
      filled_img: 'url(../../img/burger/analytics/filled.svg)',
      isActive: false,
    },
    {
      name: 'Downtime',
      route: '',
      empty_img: 'url(../../img/burger/downtime/line.svg)',
      filled_img: 'url(../../img/burger/downtime/filled.svg)',
      isActive: false,
    },
    {
      name: 'Production',
      route: '',
      empty_img: 'url(../../img/burger/production/line.svg)',
      filled_img: 'url(../../img/burger/production/filled.svg)',
      isActive: false,
    },
    {
      name: 'LogR',
      route: '',
      empty_img: 'url(../../img/burger/logR/line.svg)',
      filled_img: 'url(../../img/burger/logR/filled.svg)',
      isActive: false,
    },
    {
      name: 'Remoter',
      route: '',
      empty_img: 'url(../../img/burger/remoted/line.svg)',
      filled_img: 'url(../../img/burger/remoted/filled.svg)',
      isActive: false,
    },
  ]);

  // Methods
  function LoadTenantSwitcher() {
    console.log("Loading Tenant Switcher dialog....")
    tenantswitchshow.value = true;
  }

  function TenantSelected(tenant) {
    console.log("Tenant Selected:",tenant);
    currentTenant.value = tenant;
    localStorage.setItem("currenttenant",tenant)
    tenantswitchshow.value = false;
  }

  function TenantSelectCancel() {
    tenantswitchshow.value = false;
  }

  function editMode() {
    localStorage.setItem('edit', !store.state.edit);
    store.state.edit = !store.state.edit;
    localStorage.setItem('editMode', store.state.edit.toString());
    setTimeout(() => {
      store.state.menu = false;
    }, 500);
  };

  async function logout() {
    localStorage.removeItem("bOpus_user");
    localStorage.removeItem("exposedTreeNodes");
    localStorage.removeItem('edit');
    localStorage.removeItem('local-images');
    
    try {
      await Session.signOut();
      router.push('/login');
      return true;
    } catch (error) {
      console.log('Looks like there was a problem: \n', error);
      return false;
    }
  };
</script>

<style lang="scss" scoped>
  .buildtag {
    border-radius: 15px 15px 0px 0px;
    font-size:14px;
    background-color: rgb(240, 238, 238);
    color:rgb(86, 85, 85);
    padding:6px;
    width:200px;
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 25px;
    z-index: 1;
  }
  .tenantswitch {
    margin-left:10px;
    background-color: #aaa;
    padding:10px;
    border-radius: 4px;
    color:#fff;
    font-size: 18px;
    cursor:pointer;
  }
  .menu_wrapper{
    position: fixed;
    z-index: 2;
    left: 0px;
    top: 0px;
    height: 100svh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: border-box;
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   width: 550px;
    //   padding-left: 30px;
    //   padding-right: 30px;
    // }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      width: 400px;
      padding-left: 20px;
      padding-right: 20px;
    }
    // LAPTOP
    @media screen and (min-width: 901px) and (max-width: 1024px){
      width: 360px;
      padding-left: 20px;
      padding-right: 20px;
    }
    // TABLET
    @media screen and (min-width: 481px) and (max-width: 900px){
      width: 300px;
      padding-left: 20px;
      padding-right: 20px;
    }
    // MOBILE
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      width: 280px;
      padding-left: 24.5px;
      padding-right: 24.5px;
    }
    @media screen and (max-height: 480px) and (orientation: landscape){
      width: 280px;
      padding-left: 24.5px;
      padding-right: 24.5px;
    }
  }
  .mobile_search{
    padding: 24px;
    position: relative;
  }
  @media screen and (min-width: 600px){
    .mobile_search{
      display: none;
    }
  }
  .section_one{
    padding: 20px 0px;
    position: relative;
    border-bottom: 1px solid rgba(37, 37, 37, .1);
    background-image: url(../../../public/img/bopus/bopus.svg);
    background-size: 69px;
    background-repeat: no-repeat;
    background-position: top 15px right;
    >p{
      color: #252525;
      display: inline-block;
      margin: 0px;
    }
    >a{
      display: block;
      text-decoration: none;
      color: #787878;
      float: right;
    }
    .map{
      width: 100%;
      box-sizing: border-box;
      background-color: rgba(120, 120, 120, .05);
      margin-top: 16px;
      overflow: hidden;
      overflow-y: auto;
      position: relative;
      border-radius: 8px;
      height: calc(4.92rem + 40px);
    }
  }
  .section_two{
    padding: 20px 0px;
    overflow: hidden;
    flex-grow: 1;
    height: auto;
    @media screen and (min-width: 481px) and (max-width: 900px){
      padding: 30px 0px 20px;
    }
    >p{
      color: #444444;
      margin: 0px;
      background-color: #fff;
      z-index: 1;
      padding-bottom: 16px;
      // @media screen and (min-width: 27 01px) and (max-width: 3840px){
      //   padding-bottom: 0px;
      //   margin-bottom: 30px;
      // }
    }
    nav{
      overflow-y: scroll;
      height: calc(100% - 17px);
      ul{
        margin: 0px;
        padding: 0px;
        li{
          list-style-type: none;
          opacity: .5;
          // @media screen and (min-width: 27 01px) and (max-width: 3840px){
          //   padding-bottom: 16px;
          // }
          a{
            display: block;
            height: 40px;
            line-height: 40px;
            box-sizing: border-box;
            text-decoration: none;
            padding-left: 54px;
            color: #444444;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-position: 8px center;
            // @media screen and (min-width: 27 01px) and (max-width: 3840px){
            //   height: 56px;
            //   line-height: 56px;
            //   background-size: 36px;
            //   padding-left: 59px;
            // }
            // DESKTOP
            // @media screen and (min-width: 1025px) and (max-width: 27 00px){
            @media screen and (min-width: 1025px){
              height: 46px;
              line-height: 46px;
              background-size: 35px;
              padding-left: 54px;
            }
            // LAPTOP
            @media screen and (min-width: 901px) and (max-width: 1024px){
              height: 46px;
              line-height: 46px;
              background-size: 30px;
              padding-left: 54px;
            }
            // TABLET
            @media screen and (min-width: 481px) and (max-width: 900px){
              background-size: 24px;
              padding-left: 48px;
            }
            // MOBILE
            @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
              background-size: 24px;
              padding-left: 48px;
            }
            @media screen and (max-height: 480px) and (orientation: landscape){
              background-size: 24px;
              padding-left: 48px;
            }
          }
          a.active,a:hover{
            background-color: rgba(120, 120, 120, .05);
          }
        }
      }
    }
  }
  .section_three{
    padding: 24px 0px;
    border-top: 1px solid rgba(37, 37, 37, .1);
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    margin-top: auto;
    .user{
      height: 40px;
      position: relative;
      margin-bottom: 10px;
      cursor: pointer;
      // @media screen and (min-width: 27 01px) and (max-width: 3840px){
      //   height: 82px;
      // }
      img{
        height: 40px;
        width: 40px;
        display: inline-block;
        position: absolute;
      }
      div{
        border: 2px solid #252525;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        line-height: 36px;
        text-align: center;
      }
      p{
        font-weight: 500;
        margin: 0px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        position: absolute;
        left: 50px;
        color: #252525;
      }
    }
    nav{
      position: relative;
      ul{
        margin: 0px;
        padding: 0px;
        li{
          list-style-type: none;
          margin-bottom: 4px;
          // @media screen and (min-width: 27 01px) and (max-width: 3840px){
          //   margin-bottom: 16px;
          // }
          a,div,.edit{
            display: block;
            height: 40px;
            box-sizing: border-box;
            line-height: 40px;
            text-decoration: none;
            color: #444444;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-position: 8px center;
            background-size: 20px;
            text-indent: 8px;
            transition: all .2s;
            // @media screen and (min-width: 27 01px) and (max-width: 3840px){
            //   height: 56px;
            //   line-height: 56px;
            //   background-size: 36px;
            //   padding-left: 59px;
            // }
            // DESKTOP
            // @media screen and (min-width: 1025px) and (max-width: 27 00px){
            @media screen and (min-width: 1025px){
              height: 46px;
              line-height: 46px;
              background-size: 35px;
              padding-left: 54px;
            }
            // LAPTOP
            @media screen and (min-width: 901px) and (max-width: 1024px){
              height: 46px;
              line-height: 46px;
              background-size: 30px;
              padding-left: 54px;
            }
            // TABLET
            @media screen and (min-width: 481px) and (max-width: 900px){
              background-size: 24px;
              padding-left: 48px;
            }
            // MOBILE
            @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
              background-size: 24px;
              padding-left: 48px;
            }
            @media screen and (max-height: 480px) and (orientation: landscape){
              background-size: 24px;
              padding-left: 48px;
            }
          }
          a.active,a:hover{
            font-weight: 500;
            background-color: rgba(120, 120, 120, .05);
          }
        }
        // li:nth-child(1) a{
        //   background-image: url(../../../public/img/burger/admin/line.svg);
        // }
        .edit{
          cursor: pointer;
          border: 1px solid #787878;
          background-position: 7px center;
          background-size: 25px;
          background-image: url(../../../public/img/burger/edit/edit.svg);
        }
        .edit_on{
          cursor: pointer;
          border: 1px solid #099F45;
          background-color: #099F45;
          color: #fff;
          background-position: 7px center;
          background-image: url(../../../public/img/burger/edit/edit_on.svg);
        }
        .settings{
          background-image: url(../../../public/img/burger/settings/line.svg);
        }
        .help{
          background-image: url(../../../public/img/burger/help/line.svg);
        }
        .logout{
          background-image: url(../../../public/img/burger/logout/line.svg);
        }
        .settings:hover,.settings.active{
          background-image: url(../../../public/img/burger/settings/filled.svg);
        }
        // li:nth-child(3) a:hover,li:nth-child(2) a.active{
        //   background-image: url(../../../public/img/burger/help/filled.svg);
        // }
        .logout:hover,.logout.active{
          background-image: url(../../../public/img/burger/logout/filled.svg);
        }
      }
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px)
  and (min-height: 961px) and (orientation: portrait){
    .menu_wrapper{
      width: 300px;
    }
  }
  @media screen and (max-height: 780px){
    .menu_wrapper{
      max-height: 100vh;
      bottom: auto;
      overflow: scroll;
    }
    .section_three{
      position: relative;
    }
  }
  @media screen and (max-width: 599px){
    .menu_wrapper{
      overflow: scroll;
    }
    .mobile_search{
      border-top: 1px solid rgba(37, 37, 37, .1);
      border-bottom: 1px solid rgba(37, 37, 37, .1);
    }
    .section_three{
      position: relative;
    }
  }
  @media screen and (max-height: 500px) and (orientation: landscape){
    .menu_wrapper{
      min-height: 100%;
      height: 100%;
      bottom: auto;
      overflow: scroll;
    }
    .mobile_search{
      margin-top: 60px;
      border-top: 1px solid rgba(37, 37, 37, .1);
      border-bottom: 1px solid rgba(37, 37, 37, .1);
    }
    .section_three{
      position: relative;
    }
  }
  @media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
    .menu_wrapper{
      width: 263px;
      .section_three{
        nav{
          ul{
            li{
              a{
                background-position: 9px center;
              }
            }
          }
        }
      }
    }
  }
</style>
